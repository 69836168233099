var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "loader" },
            [
              _vm.loading
                ? _c("v-progress-circular", {
                    attrs: {
                      size: 100,
                      width: 10,
                      indeterminate: "",
                      color: "primary",
                    },
                  })
                : _vm._e(),
              _vm.integrationAlreadyPresent
                ? _c(
                    "v-alert",
                    { attrs: { dense: "", text: "", type: "info" } },
                    [
                      _vm._v(" Integration already present. Redirecting in "),
                      _c("strong", [_vm._v(_vm._s(_vm.countdown))]),
                      _vm._v(" seconds... "),
                    ]
                  )
                : _vm._e(),
              _vm.integrationSuccessful
                ? _c(
                    "v-alert",
                    { attrs: { dense: "", text: "", type: "success" } },
                    [
                      _vm._v(" Slack integration successful. Redirecting in "),
                      _c("strong", [_vm._v(_vm._s(_vm.countdown))]),
                      _vm._v(" seconds... "),
                    ]
                  )
                : _vm._e(),
              _vm.accessDeniedError
                ? _c(
                    "v-alert",
                    { attrs: { dense: "", text: "", type: "error" } },
                    [
                      _vm._v(" Access denied by user. Redirecting in "),
                      _c("strong", [_vm._v(_vm._s(_vm.countdown))]),
                      _vm._v(" seconds... "),
                    ]
                  )
                : _vm._e(),
              _vm.unknownError
                ? _c(
                    "v-alert",
                    { attrs: { dense: "", text: "", type: "error" } },
                    [
                      _vm._v(
                        " Unable to add integration, please try again. Redirecting in "
                      ),
                      _c("strong", [_vm._v(_vm._s(_vm.countdown))]),
                      _vm._v(" seconds... "),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }