<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col class="loader">
        <v-progress-circular
          v-if="loading"
          :size="100"
          :width="10"
          indeterminate
          color="primary"
        >
        </v-progress-circular>

        <v-alert v-if="integrationAlreadyPresent" dense text type="info">
          Integration already present. Redirecting in
          <strong>{{ countdown }}</strong> seconds...
        </v-alert>

        <v-alert v-if="integrationSuccessful" dense text type="success">
          Slack integration successful. Redirecting in
          <strong>{{ countdown }}</strong> seconds...
        </v-alert>

        <v-alert v-if="accessDeniedError" dense text type="error">
          Access denied by user. Redirecting in
          <strong>{{ countdown }}</strong> seconds...
        </v-alert>

        <v-alert v-if="unknownError" dense text type="error">
          Unable to add integration, please try again. Redirecting in
          <strong>{{ countdown }}</strong> seconds...
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { IntegrationTypes } from '@shared/enums';
import * as _ from 'lodash';
import { PROCESS_AUTH } from 'src/utils/apollo-mutations';
import Vue from 'vue';
import { updateCLF } from '../utils/util'
import { CheckListFields } from '@shared/enums/shared.enums'
export default Vue.extend({
  name: 'SlackIntegration',
  data() {
    return {
      countdown: 5,
      query: null,
      loading: false,
      accessDeniedError: false,
      integrationSuccessful: false,
      integrationAlreadyPresent: false,
      unknownError: false,
    };
  },
  methods: {
    countDownTimer() {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    async initAuth() {
      this.query = this.$route.query;

      if (_.isEmpty(this.query)) return this.$router.push('/integrations');

      if (this.query.error === 'access_denied') {
        this.accessDeniedError = true;
      } else {
        await this.processAuth();
      }

     this.redirect();
    },
    async processAuth() {
      this.loading = true;

      try {
        const result = await this.$apollo.mutate({
          mutation: PROCESS_AUTH,
          variables: {
            authReq: {
              code: this.query.code,
              state: this.query.state,
              type: IntegrationTypes.SLACK
            },
          },
        });
        //console.log(result)
        if(result.data.processSAAuth.content && result.data.processSAAuth.content.integrationAlreadyPresent) {
          this.integrationAlreadyPresent = true;
        } else if (result.data.processSAAuth.successMessage) {
          this.integrationSuccessful = true;
          updateCLF(CheckListFields['slackIntegration'], this)
        } else {
          this.unknownError = true;
        }
      } catch (error) {
        console.log(error)
        this.unknownError = true;
      }

      this.loading = false;
    },

    redirect() {
      this.countDownTimer();
      setTimeout(() => {
        this.$router.push('/integrations');
      }, 5000);
    },
  },
  created() {
    this.initAuth();
  },
});
</script>
